
@import 'styles/variables.scss';
.slide{
    width: 500px;
  }

  .alerta  {
    width: 250px !important; 
    height: 150px; 
    position: absolute;
    top: 30%;
    transform: translate(-50%, -50%);
    overflow: hidden; 
  }

  .header {
    background-color: map-get($theme-colors, "primary");
  }

  .buttons {
    display: flex;
    justify-content: flex-start; 
    padding: 20px 0px;
    gap: 10px; 
  }
  