  .columna {
    height: calc(100vh - 260px);
    overflow-y: auto;
  }

  .header {
    background-color: #f8b34b;
  }


  .alerta {
    width: 450px !important;
    height: 250px;
    position: absolute;
    top: 30%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }

  .aceptar {
    width: 300px !important;
    height: 220px;
    position: absolute;
    top: 30%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }


  // .buttons {
  //   float: right;
  //   padding: 20px 0px;
  //   position:relative;
  //   margin-bottom: 50px !important;
  // }

.buttons {
  display: flex;
  justify-content: flex-end; 
  padding: 20px 0px;
  gap: 10px; 
}

  .modal {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 130vw !important;
    height: 100vh !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  
  .overlayStyle {
    position: 'absolute';
    top: 0;
    left: 0;
    width: '100%';
    height: '100%';
    background-color: 'rgba(0, 0, 0, 0.5)'; 
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
    z-index: 1000 
  }