@import 'styles/variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
.tableheader{
    font-weight: bold;
    font-size: 0.8rem;
}
.tablelink{
    color: black;
    text-decoration: none;
}
.recibidovent{
    background-color: $secondary;
    color: white;
    padding:4px;
    border-radius: 3px;
}
.transitoarchivo{
    background-color: $warning;
    color: white;
    padding:4px;
    border-radius: 3px;
}
.retornadoarchivo{
    background-color: $danger;
    color: yellow;
    padding:4px;
    border-radius: 5px;
    &:hover{
        color:white;
    }
}
