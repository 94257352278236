
@import 'styles/variables.scss';
.contenedor {
    text-align: center;
    vertical-align: middle;
    margin-top: 15%;
  }

  .header {
    background-color: map-get($theme-colors, "primary");;
  }
  .error {
    background-color: #f8b34b;
  }
  
  
  .alerta  {
      width: 450px !important; 
      height: 200px !important; 
      position: absolute;
      top: 30%;
      transform: translate(-50%, -50%);
      overflow: hidden; 
    }

    .buttons {
      display: flex;
      justify-content: flex-end; 
      padding: 20px 0px;
      gap: 10px; 
    }
    