@import 'fonts.scss';
@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import './layout/loader.scss';

.modal-dialog {
    max-width: 80% !important;
    .modal-body{
        min-height:70vh;
    }
  }

.btn{
    &.btn-outline-primary{
        &:hover{
            color:white;
        }
        &:focus{
            background-color: $primary;
            color:white;
            border: 1px solid white;
            box-shadow: 2px 2px 4px #555;
            text-shadow: 2px 2px 4px #666;
        }
    }
    &.btn-primary{
        color:white;
        &:focus{
            color:white;
            border: 1px solid white;
            box-shadow: 2px 2px 4px #555;
            text-shadow: 2px 2px 4px #666;
        }
    }
    &.btn-danger{
        color:white;
        &:focus{
            color:white;
            border: 1px solid white;
            box-shadow: 2px 2px 4px #555;
            text-shadow: 2px 2px 4px #666;
        }
    }
    &.btn-warning{
        color:white;
        &:focus{
            color:white;
            border: 1px solid white;
            box-shadow: 2px 2px 4px #555;
            text-shadow: 2px 2px 4px #666;
        }
    }
    
}

input,
button,
select,
optgroup,
textarea{
    line-height:1.5 !important;
}