@import 'styles/variables.scss';

.navbarbrand{
    text-align: center;
    margin-bottom:0.3rem;
    padding-top:1rem !important;
}
.navlink {
    color: white !important;
}
.navlinkicon{
    padding-bottom:2px;
}

.myactive{
    background-color: white !important;
    color: rgb(39, 39, 39) !important;
}
.navbarlogo{
    justify-content: center !important;
    margin-top:15px !important;
    display: flex;
}
.navbarlogo:hover{
    cursor: pointer;
}

.history {
    max-height: 80vh;
    overflow-y: auto;
    flex: 1
}

.history::-webkit-scrollbar {
    width: 10px;
  }
  
  .history::-webkit-scrollbar-track {
    background: $primary;
    border-radius: 3px;
  }
  
  .history::-webkit-scrollbar-thumb {
    background: map-get($theme-colors, "secondary");
    border-radius: 11px;
  }