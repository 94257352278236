@import 'styles/variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
.label{
    font-weight:bold;
    text-align:right;
}
.statuspill{
    display:inline-block;
    padding:5px;
    border-radius: 5px;
}
.statuscreado{
    background-color: $warning;
    
}
.statusentregado{
    background-color: $primary;
}
.statusretornado{
    background-color: $danger;
}
.statuseliminado{
    background-color: $secondary;
}
.modalcenter{
    text-align:center;
    font-size: 1.2rem;
}