@import 'styles/variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.labeldata{
    text-align: right;
    font-weight: bold;
}

.onedoc{
    border: $input-border-color solid $input-border-width;
    border-radius: $input-border-radius;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding:0.4rem;
}
.recibidovent{
    background-color: $secondary;
    color: white;
    padding:4px;
    border-radius: 3px;
    display:inline-block;
}
.transitoarchivo{
    display:inline-block;
    background-color: $warning;
    color: white;
    padding:4px;
    border-radius: 3px;
}
.retornadoarchivo{
    display:inline-block;
    background-color: $danger;
    color: white;
    padding:4px;
    border-radius: 3px;
}