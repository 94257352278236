/* Color Overrides */

// $primary: #6ab42f;
$primary: rgb(39, 39, 39);
$nav-pills-link-active-color: $primary;

/* This theme colors */

$theme-odd-even-color: #efefef;
$theme-review-data-color: rgba(0, 0, 0, 0.1);

$theme-colors:(
    "danger": #dc3545,
    "primary":#2874a6,
    "warning": #f8b34b,
    "success": #28a745,
    "secondary": #5f6570
);

// #09ee79 green
// #200085 blue

/* padding overrides */
$input-padding-y: 2px;
$input-padding-x: 2px;
$input-font-family: 'Poppins';
$input-border-radius: 2px;

/* Fonts */
$font-size-base: 0.75rem;
$font-family-sans-serif: "Poppins",Arial,Helvetica,sans-serif; 
$h1-font-size: 1.6rem;
$h2-font-size: 1.4rem;
$h3-font-size: 1.2rem;
$h4-font-size: 1.1rem;

/* line height */
$line-height-base: 1.8;   

/*spaces*/
$spacer: 0.40rem;
$modal-header-padding:10px;
$modal-md: 50vw;

