
.animationctn{
    text-align:center;
    
  }
  
      @-webkit-keyframes checkmark {
      0% {
          stroke-dashoffset: 100px
      }
  
      100% {
          stroke-dashoffset: 200px
      }
  }
  
  @-ms-keyframes checkmark {
      0% {
          stroke-dashoffset: 100px
      }
  
      100% {
          stroke-dashoffset: 200px
      }
  }
  
  @keyframes checkmark {
      0% {
          stroke-dashoffset: 100px
      }
  
      100% {
          stroke-dashoffset: 0px
      }
  }
  
  @-webkit-keyframes checkmark-circle {
      0% {
          stroke-dashoffset: 480px
     
      }
  
      100% {
          stroke-dashoffset: 960px;
        
      }
  }
  
  @-ms-keyframes checkmark-circle {
      0% {
          stroke-dashoffset: 240px
      }
  
      100% {
          stroke-dashoffset: 480px
      }
  }
  
  @keyframes checkmark-circle {
      0% {
          stroke-dashoffset: 480px 
      }
  
      100% {
          stroke-dashoffset: 960px
      }
  }
  
  @keyframes colored-circle { 
      0% {
          opacity:0
      }
  
      100% {
          opacity:100
      }
  }
  
  /* other styles */
  /* .svg svg {
      display: none
  }
   */
  .inlinesvg .svg svg {
      display: inline
  }
  
  /* .svg img {
      display: none
  } */
  
  .iconordersuccesssvgpolyline {
      -webkit-animation: checkmark 0.35s ease-in-out 0.7s backwards;
      animation: checkmark 0.35s ease-in-out 0.7s backwards
  }
  
  .iconordersuccesssvgcircle {
      -webkit-animation: checkmark-circle 0.4s ease-in-out backwards;
      animation: checkmark-circle 0.4s ease-in-out backwards;
  }
  .iconordersuccesssvgcolored {
      -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
      animation: colored-circle 0.6s ease-in-out 0.7s backwards;
  } 