@import 'styles/variables.scss';
@import '~bootstrap/scss/bootstrap.scss';




.f001{
    width:100%;
    padding-bottom:10vh;
    .fs-fact{
        border: $input-border-width solid $input-border-color;
        padding: 5px;
        border-radius: $input-border-radius;
        margin-top:1rem;
        .factTools{
            float: right;
        }
    }
}

.confirmtbl{
    .row{
        border-bottom: $input-border-width solid $input-border-color;
        .confirmlbl{
            text-align: right;
            font-weight: bold;
            padding-right: 0px;
        }
        .datacol{
            color: $theme-review-data-color;
        }
        .factreview{
            border:$input-border-width solid $input-border-color;
            margin: 1rem;
            border-radius: $input-border-radius;
            .row{
                margin-left:0px;
                margin-right: 0px;
            }
            h5{
                padding-top:0.8rem;
                padding-bottom: 0.8rem;
            }

        }
        &:nth-child(even){
            background-color: $theme-odd-even-color;
        }

    }
}
.bodycentered{
    text-align: center;
}

.nexttoolbar{
    position:fixed;
    bottom: 0px;
    right: 0px;
    width:auto;
    background-color: white;
    border-top:1px solid #ccc;
    box-shadow:-1px -2px 2px #ccc;
    padding:0.5rem;
    width:calc(100% - 280px);
    text-align: right;
    font-size:1.2rem;
}
.prevbutton{
    color:white;
    font-size:1.2rem;
    font-weight: bold;
    margin-right:1rem;
}
.nextbutton{
    color:white;
    font-size:1.2rem;
    font-weight: bold;
}
.discardbutton{
    color:white;
    font-size:1.2rem;
    font-weight: bold;
    margin-right: 1rem;
}