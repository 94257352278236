@import 'styles/variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
.recibidovent{
    background-color: $secondary;
    color: white;
    padding:4px;
    border-radius: 3px;
}
.transitoarchivo{
    background-color: $warning;
    color: white;
    padding:4px;
    border-radius: 3px;
}