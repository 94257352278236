.oneitem{
    border: 2px solid #999;
    max-width: 50vw;
    padding: 1rem;
    margin-bottom: 1rem;
}
.onetoolbar{
    float:right;
}
.lbl{
    text-align: right;
}
.modal{
    color:black;
}