.container{
    width: 100%;
    height: 100vh;
    top:0px;
    left:0px;
    bottom:0px;
    right:0px;
    overflow: hidden;
    
}
.mainrow{
    height:100vh !important;
    margin-right:-47px !important;
}
.sidebarBackground{
    background-color: rgb(39, 39, 39) !important;
}
.textWhite{
    color: white !important;
    button{
        color: white !important;
    }
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    
}
.sidebarwrapper{
    min-height: 100vh !important;
    height: 100vh;
    width: 280px !important;
    margin-left: 0;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out; 
    transition: all ease 0.2s;
    background-color: rgb(39, 39, 39) !important;   
    // background-color: white !important;
}
.sidebarwrappercollapsed{
    min-height: 100vh !important;
    height: 100vh;
    width: 20px !important;
    margin-left: 0;
    overflow-y:auto;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;    
    transition: all ease 0.2s;
}

.pagecontentwrapper {
    min-width: 0;
    box-shadow: inset 0 0.5em 1.5em rgb(0 0 0 / 10%), inset 0 0.125em 0.5em rgb(0 0 0 / 15%);
    height: calc(100vh - 55px) !important;
    padding: max(1vh,1vw);
    overflow: auto;
}
.pagecontentcolumn{
    min-width: 0;
    width: calc(100vw - 280px) !important;
    box-shadow: inset 0 0.5em 1.5em rgb(0 0 0 / 10%), inset 0 0.125em 0.5em rgb(0 0 0 / 15%);
}
.pagecontentcolumnsidebarhidden{
    min-width: 0;
    width: calc(100vw - 20px) !important;
    box-shadow: inset 0 0.5em 1.5em rgb(0 0 0 / 10%), inset 0 0.125em 0.5em rgb(0 0 0 / 15%);
    transition: all ease 0.2s;
}
.navbarwrapper{
    height: 55px ;
    padding-right: 1rem;
    padding-left: 1rem;
}
.collpasebuttondisplayed{
    position:fixed;
    bottom:4px;
    left:5px;
}
.collpasebuttonhidden{
    position:relative;
    bottom:4px;
    right:0px;
}
.insidecontentdbutton{
    position:fixed;
    left:25px;
    bottom:20px;
    font-size:1rem;
}