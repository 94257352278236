@import 'styles/variables.scss';
.contenedor {
  text-align: center;
  vertical-align: middle;
  margin-top: 5%;
}

.header {
  background-color: map-get($theme-colors, "primary");;
}

.error {
  background-color: #f8b34b;
}

.change {
  // position: absolute;
  float: right;
  margin-top: 200px;
}


.alerta {
  width: 500px !important;
  height: 350px;
  position: absolute;
  top: 20%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.overlayStyle {
  margin-top: 50%;
}