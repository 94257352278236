.modalonetramite{
    border: 1px solid #ccc;
    padding: 0.5rem;
    margin: 1rem;
    box-shadow: 2px 2px 2px #ccc;
}
.lbl{
    font-weight:bold;
    text-align:right;
}
.attention{
    font-weight:bold;
    color: blue;
}
.notramite{
    font-weight: bold;
}
.oneitemlote{
    border: 1px solid #ccc;
    border-radius: 2px;
    box-shadow: 2px 2px 2px #efefef;
    padding-top: 1rem;
    margin-top: 1rem;
}
.tab{
    padding-left: 1rem;
}
.loteok{
    text-align: center;
}