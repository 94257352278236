.spinner9y7u{
    animation:spinner_fUkk 2.4s linear infinite;
    animation-delay:-2.4s
}
.spinnerDF2s{
    animation-delay:-1.6s
}
.spinnerq27e{
    animation-delay:-.8s
}
@keyframes spinner_fUkk{
    8.33%{
        x:13px;
        y:1px
    }
    25%{
        x:13px;
        y:1px
    }
    33.3%{
        x:13px;
        y:13px
    }
    50%{
        x:13px;
        y:13px
    }
    58.33%{
        x:1px;
        y:13px
    }
    75%{
        x:1px;
        y:13px
    }
    83.33%{
        x:1px;
        y:1px
    }
}