.layoutwrapper{
    width:100vw;
    height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
 
}
.centralelement{
    min-width: 480px;
    border:1px solid #ccc;
    border-radius: 5px;
    margin-top:20vh;
    padding: 1rem;
    background-color: white;
    transition: ease all 0.2s;
}