.contenedor {
  max-height: 500px;
  overflow-y: auto;
  border: 1px solid #ccc;
}

.div {
  width: 100%;
}

.tabla {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.tabla th, .tabla td {
  border: 1px solid #0e0c0c;
  padding: 8px;
  text-align: left;
}

.tabla tr {
  background-color: #dfd4d4;
}
.buttons {
  display: flex;
  justify-content: flex-end; 
  padding: 20px 0px;
  gap: 10px; 
}


.columna {
  height: calc(100vh - 260px);
  overflow-y: auto;
}


.header {
  background-color: rgb(25, 135, 84);
}
.error {
  background-color: #f8b34b;
}

.alerta  {
    width: 250px !important; 
    height: 150px; 
    position: absolute;
    top: 30%;
    transform: translate(-50%, -50%);
    overflow: hidden; 
  }