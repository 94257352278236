.uploadwrapper{
    width:100%;
    height: 100%;
    position:relative;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    margin:0px;
    padding:0px;
}
.upwrapper{
    width:100%;
    height: 100%;
    position:relative;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    margin:0px;
    padding:0;
}
.droparea{
    border:2px dashed #ccc;
    border-radius:3px;
    width:100%;
    min-height: 100px;
    height: 100%;
    opacity: 0.5;
    transition: ease all 0.3s;
    text-align: center;
    cursor:pointer;
    display:flex;
    align-items: center;
    justify-content: center;
}
.droparea:hover {
    opacity:0.8;
    font-size: 130%;
    border-width: 4px;
  
}
.fileinput{
    display:none;
    opacity: 0;
    height:0px;
    width:0px;
}
.successmsg{
    text-align: center;
    
}
.iconc{
    text-align: center;    
}
.errormsg{
    text-align:center;
}
.draggingoverwindow{
    border-color:  rgb(249, 198, 153);
    border-width: 4px;
    opacity: 0.8;
}
.draggingoverarea{
    border-color:  rgb(253, 154, 137);
    border-width: 6px;
    opacity: 1;
    font-size:140%;
    background-color: ivory;
}