.columna{
    height: 210%;
}

.buttons{
    margin: 15px 0px;
    float: right;
    padding: 0px 50px;
}

.text{
    color:red;
}
