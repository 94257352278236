.buttons{
    margin-right: 30px;
    width: 10%;
}

.form{
    width: 200px;
    margin-right: 5%;
}

